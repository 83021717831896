import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {Table} from 'primeng/table';
import {Department} from '../../models/Department';
import {Appointment} from '../../models/Appointment';
import {AppointmentService} from '../../core/appointment.service';
import {PrintService} from '../../core/print.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MessageService]
})
export class ListComponent implements OnInit {

  @ViewChild('subjectTable', { static: true }) sTable: Table;
  appointments: Appointment[] = [];
  totalRecords =  0;
  deleteDisplay = false;
  deleteMessage = 'هل انت متأكد من حذف هذا الموضوع؟';
  selectedId = 0;
  selectedWord = '';
  // selectedWordType = '';
  cols: any[];
  loading: boolean;
  pageSize = 10;
  citizenName = '';
  appointmentNo = '';
  firstload = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiService: AppointmentService,
              public printService: PrintService,
              private messageService: MessageService,
              private msgService: SystemMessagingService) { }

  async ngOnInit() {
    this.loading = true;
  }

  LazyLoading( event: LazyLoadEvent ) {
    this.loading = true;
    if ( !event.sortField ) {
      event.sortField = 'updatedDate';
      event.sortOrder = -1;
    }
    if ( this.firstload ) {
      event.sortOrder = -1;
      this.firstload = false;
    }
    this.pageSize = event.rows;
    const pNo = (event.first + event.rows ) / event.rows;
    this.callDepService( pNo, this.pageSize, event.sortField,  event.sortOrder === -1 ? false : true);
  }


  callDepService( pId: number, pSize: number, sOrder: string, sDirect: boolean ) {


    const pinfo = { // new PagingAndFilteringInfo();
      index: pId,
      size: pSize,
      isAsc: sDirect,
      orderBy: sOrder,
      fields : this.getSearchFields()
    };

    this.apiService.paging( pinfo ).subscribe( result => {
      this.appointments = result.items;
      this.totalRecords = result.totalItems;

      this.loading = false;
    });
  }



  getSearchFields(): any {

    const fields: any = {};
    if ( this.citizenName !== null && this.citizenName.length > 0  )  {
      fields[ 'citizenName' ] = this.citizenName;
    }
    if ( this.appointmentNo !== null && this.appointmentNo.length > 0  )  {
      fields[ 'appointmentNo' ] = this.appointmentNo;
    }
    return fields;

  }


  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }
  onPrint(rowData: any) {
    console.log( 'selectRow');
   // this.router.navigateByUrl('/department/edit/' + rowData.id );
    const reportData = [rowData.appointmentNo.toString()];
    this.printService.printDocument('booking-extended', reportData);

  }
  onEdit(rowData: any) {
    console.log( 'selectRow');
    this.router.navigateByUrl('/admin/appointment/edit/' + rowData.appointmentNo );

  }
  onDelete( data: Department ) {
    this.selectedId = data.id;
    this.selectedWord = data.depName;
    this.deleteDisplay = true;
  }


  async onConfirmDelete( e: any ) {

    this.deleteDisplay = false;
    if ( e === false ) {
      return;
    }

    this.messageService.clear();
    this.selectedWord = '';
    const res = await this.apiService.delete( this.selectedId);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));
    this.sTable.first = 0;
    this.callDepService( 1, this.pageSize, 'updatedDate', false);
    this.deleteDisplay = false;
  }


  onSearch() {
    this.callDepService( 1, this.pageSize, 'updatedDate', false);
  }

}
